import React from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
  lastName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your last name"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  company: yup
    .string()
    .trim()
    .min(1, "Please enter a valid company name")
    .max(50, "Please enter a valid company name"),
  contactReason: yup.string().required("Please specify your contact reason"),
  message: yup
    .string()
    .trim()
    .max(500, "The message cannot contain more than 500 characters"),
});

const Form = () => {
  const { t } = useTranslation();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    contactReason: "",
    message: "",
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box style={{ marginBottom: "20px" }}>
        <Typography color={"textSecondary"}>
          {t("form.completeForm")}
        </Typography>
        <Typography color={"textSecondary"}>
          {t("form.responseTime")}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  label={t("form.firstName")}
                  variant="outlined"
                  name={"firstName"}
                  fullWidth
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t("form.lastName")}
                  variant="outlined"
                  name={"lastName"}
                  fullWidth
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t("form.email")}
                  variant="outlined"
                  name={"email"}
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t("form.company")}
                  variant="outlined"
                  name={"company"}
                  fullWidth
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.company && Boolean(formik.errors.company)
                  }
                  helperText={formik.touched.company && formik.errors.company}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  size={"large"}
                  variant={"contained"}
                  type={"submit"}
                  // fullWidth
                >
                  {t("form.sendRequest")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  select
                  label={t("form.contactReason")}
                  variant="outlined"
                  name={"contactReason"}
                  fullWidth
                  value={formik.values.contactReason}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactReason &&
                    Boolean(formik.errors.contactReason)
                  }
                  helperText={
                    formik.touched.contactReason && formik.errors.contactReason
                  }
                >
                  {Object.keys(t("form.contactReasons", { returnObjects: true })).map((key) => (
                    <MenuItem key={key} value={t(`form.contactReasons.${key}`)}>
                      {t(`form.contactReasons.${key}`)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t("form.message")}
                  variant="outlined"
                  name={"message"}
                  fullWidth
                  multiline
                  rows={4}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={formik.touched.message && formik.errors.message}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;

import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Grid,
  Typography,
  Autocomplete,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  ListItemIcon,
  Paper,
} from "@mui/material";

const FilterField = ({ name, type, unit, onChange, options = [], value, multipler }) => {
  const [inputValue, setInputValue] = useState(value || '');  // Local state to handle input value
  const [minValue, setMinValue] = useState(value?.min || '');
  const [maxValue, setMaxValue] = useState(value?.max || '');
  const [selectedOptions, setSelectedOptions] = useState(value || []);
  const [filteredOptions, setFilteredOptions] = useState(options); // Filtered options for autocomplete

  useEffect(() => {
    if (type === "checkboxlist" && Array.isArray(value)) {
      setSelectedOptions(value);
    } else if (type === "minmax" && value) {
      setMinValue(String(value.min  || '').replace('.', ','));
      setMaxValue(String(value.max  || '').replace('.', ','));
    } else {
      setInputValue(String(value || '').replace('.', ','));
    }
  }, [type, value]);

  // Normalize input for internal handling (replace comma with dot)
  const normalizeDecimalInput = (input) => {
    return input.replace(',', '.');
  };

  // Allow only digits, commas, and dots in the input
  const validateInput = (input) => {
    // tutaj sprawdzenie inputu
    return input.replace(/[^0-9,.]/g, ''); // Allow only digits, commas, and dots
  };

  // Handle min value change
  const handleMinChange = (e) => {
    let newMinValue = e.target.value;
    newMinValue = validateInput(newMinValue); // Validate input here
    setMinValue(newMinValue); // Show the comma version in the input
    const normalizedValue = normalizeDecimalInput(newMinValue); // Internally store with dot
    onChange(`min ${name}`, normalizedValue);
  };

  // Handle max value change
  const handleMaxChange = (e) => {
    let newMaxValue = e.target.value;
    newMaxValue = validateInput(newMaxValue); // Validate input here
    setMaxValue(newMaxValue); // Show the comma version in the input
    const normalizedValue = normalizeDecimalInput(newMaxValue); // Internally store with dot
    onChange(`max ${name}`, normalizedValue);
  };

  const handleChange = (fieldName, newValue) => {
    let newInputValue = newValue;
    newInputValue = validateInput(newInputValue); // Validate input here
    setInputValue(newInputValue);  // Show the comma version in the input
    const normalizedValue = normalizeDecimalInput(newInputValue);  // Internally store with dot
    onChange(fieldName, normalizedValue);
  };

  // Handle the change of selected options in Autocomplete
  const handleAutocompleteChange = (event, newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions); // Update local selected options state
    onChange(name, newSelectedOptions); // Pass selected options to parent onChange
  };

  // Handle direct selection from the scrollable list
  const handleListSelectionChange = (option) => {
    const currentIndex = selectedOptions.indexOf(option);
    let newSelectedOptions = [...selectedOptions];

    if (currentIndex === -1) {
      newSelectedOptions.push(option); // Add if not already selected
    } else {
      newSelectedOptions.splice(currentIndex, 1); // Remove if already selected
    }

    setSelectedOptions(newSelectedOptions);
    onChange(name, newSelectedOptions);
  };

  // Sort the options to show selected ones on top

  let sortedOptions = [...filteredOptions]; // Start with all options
  if(type === "checkboxlist") {
    sortedOptions = [
      ...selectedOptions, // Show selected options first
      ...filteredOptions.filter(option => !selectedOptions.includes(option)), // Then show the unselected options
    ];
  
  }

  const getLabelWithUnit = (label) => {
    return unit ? `${label} (${unit})` : label;
  };

  return (
    <Box>
      {/* For Equal Type */}
      {type === "eq" && (
        <TextField
          fullWidth
          size="small"
          label={getLabelWithUnit(name)}
          variant="outlined"
          value={inputValue}  // Use local state for controlled input
          onChange={(e) => handleChange(name, e.target.value)}
        />
      )}

      {/* For MinMax Type */}
      {type === "minmax" && (
        <Box>
          <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 2 }}>
            {/* Label column */}
            <Grid item xs={4}>
              <Typography variant="body1">
                {getLabelWithUnit(name)}
              </Typography>
            </Grid>
            {/* Min column */}
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label={`Min ${unit ? `(${unit})` : ""}`}
                variant="outlined"
                value={minValue} // Display with commas
                onChange={handleMinChange} // Store with dots internally
              />
            </Grid>
            {/* Max column */}
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label={`Max ${unit ? `(${unit})` : ""}`}
                variant="outlined"
                value={maxValue} // Display with commas
                onChange={handleMaxChange} // Store with dots internally
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {/* For Checkbox List Type: Multi-Select Autocomplete with scrollable list */}
      {type === "checkboxlist" && (
        <Box>
          {/* Autocomplete to search and narrow down options */}
          <Autocomplete
            multiple
            options={options} // Options provided as a prop
            value={selectedOptions} // Currently selected options
            onChange={handleAutocompleteChange} // Handle change when user selects options
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={getLabelWithUnit(name)} // The label with unit (if any)
                placeholder="Search and select..."
              />
            )}
            sx={{ marginBottom: 2 }}
          />

          {/* Scrollable list below the Autocomplete for direct selection */}
          <Paper elevation={0} sx={{ maxHeight: 200, overflow: 'auto' }}>
            <List>
              {sortedOptions.map((option) => (
                <ListItem
                  key={option}
                  button
                  onClick={() => handleListSelectionChange(option)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedOptions.indexOf(option) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default FilterField;

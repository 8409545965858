import { useState } from "react";
import axios from "axios";
import config from "config/config";

const useSendEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [error, setError] = useState(null);

  const formatBasketItemsToHTML = (basket) => {
    let html = '<table border="1" cellpadding="5" cellspacing="0">';
    html += '<tr><th>Kod produktu</th><th>Opis produktu</th></tr>';
  
    Object.keys(basket).forEach(itemCode => {
      const { details, description } = basket[itemCode];
      // const description = details && details.Description ? details.Description : 'Description not available';
      html += `<tr>
        <td>${itemCode}</td>
        <td>${description}</td>
      </tr>`;
    });
  
    html += '</table>';
    return html;
  };
  

  const formatFormDataToHTML = (formData) => {
    return `
      <p><strong>Imię:</strong> ${formData.firstName}</p>
      <p><strong>Nazwisko:</strong> ${formData.lastName}</p>
      <p><strong>Email:</strong> ${formData.email}</p>
      <p><strong>Firma:</strong> ${formData.company}</p>
      <p><strong>Wiadomość:</strong> ${formData.message}</p>
      <p><strong>Produkty:</strong></p>
    `;
  };

  const sendEmail = async (formData, basket) => {
    setIsLoading(true);
    setIsSuccess(null);
    setError(null);

    const basketHTML = formatBasketItemsToHTML(basket);
    const formHTML = formatFormDataToHTML(formData);

    try {
      const response = await axios.post(`${config.API_BASE_URL}/send-email`, {
        ...formData,
        html: formHTML + basketHTML
      });
      setIsSuccess(true);
      // console.log('Email sent:', response.data);
    } catch (err) {
      setIsSuccess(false);
      setError(err);
      console.error('There was an error sending the email:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return { sendEmail, isLoading, isSuccess, error };
};

export default useSendEmail;

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import Form from "../../../ContactUs/components/Form";

const ContactLanding = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "5vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(4),
      }}
    >
      <Helmet>
        <title>{t("topbar.pages.company.main")}</title>
      </Helmet>
      <Box
        component="img"
        src={'/assets/contact_bateryjka.png'}
        sx={{
          display: {
            xs: 'none',
            sm : 'none',
            lg: 'block'
         },
          position: "absolute",
          right: '-10%', 
          top: "90%", 
          width: "25%",
          transform: "rotate(0deg) translateY(-50%)", 
          backgroundImage: '/assets/conctact_bateryjka.png',
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
      <Box>
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            marginBottom: theme.spacing(4),
          }}
        >
          {t("contactLanding.getInTouch")}
        </Typography>
        <Box>
          <Form />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactLanding;

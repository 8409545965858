import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Grid2 from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const FeaturedBatteryGroups = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box>
      {/* Header Section */}
      <Box marginBottom={10} marginTop={4} sx={{ paddingX: { xs: 2, sm: 4, md: 6 }}}>
        <Typography variant="body1"  color="text.secondary" gutterBottom>
          Akyga Battery is a brand that has been present on the European market for years. It has over 10 years of experience in the production of high-quality batteries and accumulators. The Akyga brand was established in 2005, and in 2013 the first cells with the Akyga Battery logo were introduced to the offer.
        </Typography>
        <br></br>
        <Typography variant="body1"  color="text.secondary" >
          The company has an extensive portfolio of standard products, but thanks to the support of a qualified technical department and in cooperation with the production department, it prepares dedicated solutions according to customer requirements.
        </Typography>
      </Box>

      {/* Lithium-polymer cells Section */}
      <Box sx={{ paddingX: { xs: 2, sm: 4, md: 6 }}}>
      <Grid2 container  spacing={10}>
        <Grid
          item
          container
          xs={12}
          spacing={4}
          direction="row"
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Box>
              <Typography variant="h5" sx={{ fontWeight: 700 }} gutterBottom>
                Lithium-polymer cells
              </Typography>
              <Typography  variant="body1" color="text.secondary">
                Lithium-Polymer is the leading type of cells in the Akyga Battery brand portfolio, the scope of the offer of this type of cells is gradually expanded. Currently, there are over 1,000 different types of batteries with capacities ranging from even less than 10mAh to over 10,000mAh.
              </Typography>
              <br></br>
              <Button 
                  color="primary"
                  variant="contained"
                  size="large"
                  sx={{ mt: 2 }}
                  onClick={() => navigate('/products/Lithium-polymer%20cells/list')}
              >
                Check Li-Po offer
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
          <Box
            component="img"
            src={theme.palette.mode === 'light' ? '/assets/dlugie.png' : '/assets/dlugie.png'}
            alt="Lithium-polymer cells"
            width="auto"
            maxWidth="100%"
            height="300px"
            sx={{
              filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.2))',
              transition: 'filter 0.3s ease-in-out',
              '&:hover': {
                filter: 'drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.4))', 
              },
            }}
          />
          </Grid>
        </Grid>

        {/* Li-Ion cells Section */}
        <Grid
          item
          container
          xs={12}
          spacing={4}
          direction="row-reverse"
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Box>
              <Typography variant="h5" sx={{ fontWeight: 700 }} gutterBottom>
                Li-Ion cells
              </Typography>
              <Typography  variant="body1" color="text.secondary">
                The most commonly used type of Li-Ion cells are those in size 18650 or 21700. They have different capacity, lifespan (charging cycles) and maximum charge/discharge currents depending on the chemical mixture used.
                <br /><br />
                These batteries are characterized by long life, relatively small dimensions and high energy density. They are widely used throughout the electromobility industry. By building a package composed of even several hundred cells, you can obtain large energy banks.
                <br /><br />
                Li-Ion cells in button cell battery housings, such as LIR2016 or LIR2032, are also becoming popular. It is an alternative to primary cells. When properly used, they can last up to 300 charging cycles.
              </Typography>
              <br></br>
              <Button 
                  color="primary"
                  variant="contained"
                  size="large"
                  sx={{ mt: 2 }}
                  onClick={() => navigate('/products/Li-Ion%20cells/list')}
              >
                Check Li-Ion offer
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
            <Box
              component="img"
              // src={theme.palette.mode === 'light' ? '/assets/b2_l.png' : '/assets/b2_l.png'}
              // src={theme.palette.mode === 'light' ? '/assets/ultrabatteries.png' : '/assets/ultrabatteries.png'}
              src={theme.palette.mode === 'light' ? '/assets/akumulatorlion.png' : '/assets/akumulatorlion.png'}  
              // src={theme.palette.mode === 'light' ? '/assets/lir3048.png' : '/assets/lir3048.png'}
              alt="Li-Ion cells"
              width="auto"
              maxWidth="100%"
              height="300px"
              sx={{
                filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.2))', 
                transition: 'filter 0.3s ease-in-out',
                '&:hover': {
                  filter: 'drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.4))', 
                },
              }}
            />
          </Grid>
        </Grid>

        {/* Batteries Section */}
        <Grid
          item
          container
          xs={12}
          spacing={4}
          direction="row"
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Box>
              <Typography variant="h5" sx={{ fontWeight: 700 }} gutterBottom>
                Batteries
              </Typography>
              <Typography color="text.secondary">
                We have also a specialized department focused on primary batteries. Such as:
                <br /><br />
                • <b>Alkaline</b> – mostly used in consumer electronics, decent Energy density, reliable cost;
                <br />
                • <b>Li-SOCl2</b> – batteries with extremely long lifetime and without passivation, dedicated for meters, IoT, sensors;
                <br />
                • <b>Li-MnO2</b> – typical button cells and also soft pouch cells, tens different shapes and capacitances with custom tabs/leads.
              </Typography>
              <br></br>
              <Button 
                  color="primary"
                  variant="contained"
                  size="large"
                  sx={{ mt: 2 }}
                  onClick={() => navigate('/products/Batteries/list')}
                  
              >
                Check Batteries offer
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
            <Box
              component="img"
              // src={theme.palette.mode === 'light' ? '/assets/b3_l.png' : '/assets/b3_l.png'}
              // src={theme.palette.mode === 'light' ? '/assets/ultrabatteries.png' : '/assets/ultrabatteries.png'}  
              src={theme.palette.mode === 'light' ? '/assets/ultrabatteries.png' : '/assets/ultrabatteries.png'} 
              // src={theme.palette.mode === 'light' ? '/assets/alkaliczne.png' : '/assets/alkaliczne.png'}              
              alt="Batteries"
              width="auto"
              maxWidth="100%"
              height="300px"
              sx={{
                filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.2))',
                transition: 'filter 0.3s ease-in-out',
                '&:hover': {
                  filter: 'drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.4))', 
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid2>
    </Box>
    </Box>
  );
};

export default FeaturedBatteryGroups;

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Container from 'components/Container';
import { motion } from 'framer-motion';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import WebIcon from '@mui/icons-material/Language';

const distributors = [
  {
    country: "Belgium",
    company: "Vanmed",
    address: "Leperleet 10, 8200 - Brugge",
    phone: "+32 47 476 64 43",
    email: "office@vanmed.be",
    website: "www.vanmed.be"
  },
  {
    country: "Czech Republic",
    company: "BATTEX spol. s r.o.",
    address: "",
    phone: "+420 312 510 011",
    email: "battex@battex.cz",
    website: "www.battex.cz"
  },
  {
    country: "Czech Republic",
    company: "TME Czech Republic, s.r.o.",
    address: "Slévárenská 406/17, 709 00 Ostrava - Mariánské Hory",
    phone: "+420 59 66 33 105",
    email: "tme@tme.cz",
    website: "www.tme.cz"
  },
  {
    country: "Denmark",
    company: "Agora Sourcing ApS",
    address: "Nymøllevej 50, 2800 Kongens Lyngby",
    phone: "+45 53 53 57 58",
    email: "info@agorasourcing.com",
    website: "www.agorasourcing.com"
  },
  {
    country: "Germany",
    company: "TME Germany GmbH",
    address: "Dohnanyistraße 28-30, 04103 Leipzig",
    phone: "+49 341 2120340",
    email: "tme@tme-germany.de",
    website: "www.tme-germany.de"
  },
  {
    country: "Greece",
    company: "Batteryword",
    address: "Dodekanisou 23 Kentro, 54626 Thessaloniki",
    phone: "+23(10)500015",
    email: "info@batteryworld.gr",
    website: "www.batteryworld.gr"
  },
  {
    country: "Hungary",
    company: "TME Hungary Kft.",
    address: "Hungária körút 162., 1146 Budapest",
    phone: "+36 1 220 67 56",
    email: "tme@tme.hu",
    website: "www.tme.hu"
  },
  {
    country: "Italy",
    company: "TME ITALIA S.r.l.",
    address: "Via Zanica 19K, 24050 Grassobbio",
    phone: "+39 035 03 93 111",
    email: "tme@tme-italia.it",
    website: "www.tme-italia.it"
  },
  {
    country: "Lithuania",
    company: "Vygento Ličio firma \"Evita\"",
    address: "Basanavičiaus str. 35, LT-03109 Vilnius",
    phone: "+370-5-2132789",
    email: "info@evita.lt",
    website: "www.evita.lt"
  },
  {
    country: "Lithuania",
    company: "Lemona",
    address: "P. Lukšio g. 19, LT-09132 Vilnius",
    phone: "+370 (616) 19222",
    email: "info@lemona.lt",
    website: "www.lemona.lt"
  },
  {
    country: "Netherlands",
    company: "Transfer Multisort Elektronik B.V.",
    address: "Croy 7, 5653 LC Eindhoven",
    phone: "+31 407 370 457",
    email: "tme@tme-benelux.nl",
    website: "www.tme-benelux.nl"
  },
  {
    country: "Poland",
    company: "AUDIO-DESIGN",
    address: "ul. os.Centrum B7 Nowa Huta, 31-927 Kraków",
    phone: "+48 888 25 00 19",
    email: "biuro@audiodesign.info.pl",
    website: "www.audiodesign.info.pl"
  },
  {
    country: "Poland",
    company: "BOTLAND B. DERKACZ SP. J.",
    address: "ul. Gola 25A, 63-640 Bralin",
    phone: "+48 62 593 10 54",
    email: "biuro@botland.pl",
    website: "www.botland.pl"
  },
  {
    country: "Poland",
    company: "Firma Piekarz Sp.J.",
    address: "ul. Wólczyńska 206, 01-919 Warszawa",
    phone: "+48 22 599 49 70",
    email: "sprzedaz@piekarz.pl",
    website: "www.piekarz.pl"
  },
  {
    country: "Poland",
    company: "Transfer Multisort Elektronik Sp. z o.o.",
    address: "ul. Ustronna 41, 93-350 Łódź",
    phone: "+48 42 645 55 55",
    email: "dso@tme.pl",
    website: "www.tme.eu"
  },
  {
    country: "Romania",
    company: "Sprinter 2000 S.A.",
    address: "Calea Feldioarei nr.60, 500483 - Brasov",
    phone: "+40-0268472333",
    email: "comenzi@sprinter-distribution.ro",
    website: "www.sprinter2000.ro"
  },
  {
    country: "Romania",
    company: "Transfer Multisort Elektronik S.R.L.",
    address: "B-dul Regele Carol I 36 Apartament 10 300180 Timișoara",
    phone: "+40 356467401",
    email: "tme@tme.ro",
    website: "www.tme.ro"
  },
  {
    country: "Slovakia",
    company: "TME Slovakia, s.r.o.",
    address: "Martina Rázusa 23, 010 01 Žilina",
    phone: "+421 415 002 047",
    email: "tme@tme.sk",
    website: "www.tme.sk"
  },
  {
    country: "Spain",
    company: "Transfer Multisort Elektronik S.L.U.",
    address: "Calle Rejas, num. 2 Planta 3, Puerta 21, 28821 Coslada (Madrid)",
    phone: "+34 911 234 771",
    email: "iberica@tme.eu",
    website: "www.tme.eu"
  },
  {
    country: "United Kingdom",
    company: "Transfer Multisort Elektronik Ltd.",
    address: "Coleshill House 1 Station Road / Suite 1c Coleshill, Birmingham B46 1HT",
    phone: "+44 1675790026",
    email: "office@tme-uk.eu",
    website: "www.tme-uk.eu"
  }
];


// Group distributors by country
const distributorsByCountry = distributors.reduce((acc, distributor) => {
  if (!acc[distributor.country]) {
    acc[distributor.country] = [];
  }
  acc[distributor.country].push(distributor);
  return acc;
}, {});

const FeaturesWithCardRepresentation = () => {
  const theme = useTheme();

  const sortedCountries = Object.entries(distributorsByCountry).sort((a, b) => b[1].length - a[1].length);

  return (
    <Box>
      <Container>
        {sortedCountries.map(([country, distributors], countryIndex) => (
          <Box key={countryIndex} marginBottom={4}>
            <Typography variant="h4" gutterBottom>
              {country}
            </Typography>
            <Grid container spacing={4}>
              {distributors.map((item, i) => (
                <Grid item xs={12} sm={6} md={4} key={i}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: i * 0.2 }}
                  >
                    <Box component={Card} padding={4} width={1} height={1} bgcolor={'alternate.main'} boxShadow={2} borderRadius={2}>
                      <Box display={'flex'} flexDirection={'column'} height={1}>
                        <Typography variant={'h6'} gutterBottom sx={{ fontWeight: 500 }}>
                          {item.company}
                        </Typography>
                        <Typography color="text.secondary">
                          {item.address}, {item.city}
                        </Typography>
                        {item.phone && (
                          <Typography color="text.secondary">
                            <PhoneIcon sx={{ mr: 1 }} /> {item.phone}
                          </Typography>
                        )}
                        {item.email && (
                          <Typography color="text.secondary">
                            <EmailIcon sx={{ mr: 1 }} /> <a href={`mailto:${item.email}`}>{item.email}</a>
                          </Typography>
                        )}
                        {item.website && (
                          <Typography color="text.secondary">
                            <WebIcon sx={{ mr: 1 }} /> <a href={item.website} target="_blank" rel="noopener noreferrer">{item.website}</a>
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Container>
    </Box>
  );
};

export default FeaturesWithCardRepresentation;
const pages = {
  main: [
    {
      title: 'topbar.pages.company.main',
      href: '/',
    },
    {
      title: 'topbar.pages.products',
      href: '/products/291,300,301,51',
    },
    {
      title: 'topbar.pages.support.certificates',
      href: '/certificates',
    },
    {
      title: 'topbar.pages.support.whereToBuy',
      href: '/distributors',
    },
    // {
    //   title: 'topbar.pages.support',
    //   id: 'support',
    //   items: [
    //     { title: 'topbar.pages.support.certificates', href: '/certificates' },
    //     { title: 'topbar.pages.support.whereToBuy', href: '/distributors' }
    //   ],
    // },
    {
      title: 'topbar.pages.company.contactUs',
      href: '/contact',
    },
  ],
};

export default pages;

import { useState, useEffect } from "react";
import axios from "axios";
import config from '../config/config';

const useProductData = (categoryName, partNumber, filters, searchInput) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // if (!searchInput) return;

    const fetchData = async () => {
      setLoading(true);
      let filteredData = await getProductsApi(categoryName, filters, searchInput, partNumber);
      setData(filteredData);
      setLoading(false);
    };

    const debounceFetch = setTimeout(() => {
      fetchData();
    }, 500);

    return () => clearTimeout(debounceFetch);
  }, [categoryName, partNumber, filters, searchInput]);

  const getProductsApi = async (categoryName, filters, searchInput, partNumber) => {
    let searchFilters = [];

    let categoryCode = null;
    if (categoryName) {
      try {
        const groupResponse = await axios.get(`${config.API_BASE_URL}/group/${categoryName}/id`);
        categoryCode = groupResponse.data.groupId;
      } catch (error) {
        console.error('Error fetching category code:', error);
      }
    }

    if (filters) {
      searchFilters = [...filters];
    }

    if (searchInput) {
      searchFilters.push({
        columnName: "Description",
        value: "%" + searchInput + "%",
        operator: "iLike"
      });
    }

    // if (partNumber) {
    //   searchFilters.push({
    //     columnName: "Part Number",
    //     value: partNumber,
    //     operator: "eq"
    //   });
    // }

    if (categoryCode && categoryCode !== -1) {
      searchFilters.push({
        columnName: "Group",
        value: categoryCode,
        operator: "eq"
      });
    }

    const response = await axios.post(`${config.API_BASE_URL}/search`, searchFilters);
    return response.data;
  };

  return data;

};

export default useProductData;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const NavButton = ({ title, id, url, colorInvert = false }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState('');

  const handleClick = () => {
    navigate(url);
  };

  const linkColor = colorInvert ? 'common.white' : 'text.primary';

  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  return (
    <Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        aria-describedby={id}
        sx={{
          cursor: 'pointer',
          padding: theme.spacing(1),
          transition: 'background-color 0.3s ease',

        }}
        onClick={handleClick}
      >
        <Typography
          fontWeight={activeLink === url ? 700 : 400}
          color={linkColor}
          sx={{
            '&:hover': {
              fontWeight: 600,
            }
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

NavButton.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  colorInvert: PropTypes.bool,
};

export default NavButton;

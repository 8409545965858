import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { useTranslation } from 'react-i18next';
import { VerticallyAlignedBlogCardsWithShapedImage } from './components';

const CertificatesOld = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Container>
       <VerticallyAlignedBlogCardsWithShapedImage/>
      </Container>
    </Main>
  );
};

export default CertificatesOld;

import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "components/Container";
import Main from "layouts/Main";
import useProductData from '../../../Hooks/useProductData';
import useTableData from 'Hooks/useTableData';
import useCategoryDetails from 'Hooks/useCategoryDetails';
import useCategories from 'Hooks/useCategories';
import useQuery from 'Hooks/useQueryParam';
import mockData from "API/mockData/Complete";

import { ProductsTable, ProductsCards, FiltersWithDropdown } from './components';

const Simple = () => {
  const theme = useTheme();
  const params = useParams();
  const queryParamsGetter = useQuery();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchInput, setSearchInput] = useState("");
  const [appliedFilters, setAppliedFilters] = useState([]);

  const partNumber = queryParamsGetter.get("partNumber");
  const filteredData = useProductData(params.name, partNumber, appliedFilters, searchInput);
  const tableData = useTableData(params.name);
  const categories = useCategories();
  const groups = useCategoryDetails(params.name);

  const hasTableData = tableData && Object.keys(tableData).length > 0;

  const handleSearchChange = (event, value) => {
    setSearchInput(value);
  };

  const ratedVoltageNumbers = mockData
    .map(x => x["Rated Voltage"])
    .filter(item => !isNaN(parseFloat(item)))
    .map(item => parseFloat(item));

  // Wait for categories to be loaded before rendering FiltersWithDropdown
  const categoriesLoaded = categories && categories.length > 0;


  /*
      All right, that's one terrible workaround.
      I'd suggest asing Slawek to add basicUnit to controls information
      When that happens, simply ditch this function and enchanced filters and replace with previous filters :)
  */
  const enhanceFiltersWithBasicUnit = (groups) => {
    if (!groups || !groups.filters || !groups.filters.filterGroups) {
      return null;
    }

    const { columns, filters } = groups;
  
    const enhancedFilterGroups = filters.filterGroups.map(filterGroup => {
      const enhancedControls = filterGroup.controls.map(control => {
        const matchingColumn = columns.find(column => column.name === control.column);
        
        if (matchingColumn) {
          return {
            ...control,
            basicUnit: matchingColumn.basicUnit,
          };
        }

        return control;
      });

      return {
        ...filterGroup,
        controls: enhancedControls,
      };
    });
  
    return {
      ...filters,
      filterGroups: enhancedFilterGroups,
    };
  };
  
  const enhancedFilters = enhanceFiltersWithBasicUnit(groups);

  return (
    <Main>
      <Box bgcolor={"alternate.main"}>
        <Container>
          {hasTableData && categoriesLoaded && enhancedFilters && (
            <FiltersWithDropdown
              name={params.name}
              handleSearchChange={handleSearchChange}
              mockData={filteredData}
              splashImage={tableData.groupDetails.imageSrc}
              siblings={tableData.siblings}
              categories={categories}
              filteredData={filteredData}
              // filters={groups.filters} // Old
              filters={enhancedFilters}  // Workaround
              handleFilterApply={setAppliedFilters}
              partNumber={partNumber}
            />
          )}
          <div style={{ overflowX: "auto" }}>
              {/* {isMobile ? <ProductsCards filteredData={filteredData}/> : <ProductsTable columns={groups.columns} filteredData={filteredData}/>} */}
              {hasTableData && (
                <ProductsTable columns={groups.columns} filteredData={filteredData}/>
              )}
          </div>
        </Container>
      </Box>
    </Main>
  );
};

export default Simple;

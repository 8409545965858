import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const NavItem = ({ title, items, href }) => {
  const theme = useTheme();
  const [activeLink, setActiveLink] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const hasActiveLink = () => items?.find((i) => i.href === activeLink);

  const commonStyles = {
    width: '100%',
    textTransform: 'none',
    justifyContent: 'flex-start',
    padding: theme.spacing(1.5, 2),
    fontWeight: hasActiveLink() ? 600 : 400,
    color: hasActiveLink() ? theme.palette.primary.main : theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
  };

  return (
    <Box marginBottom={1}>
      {items && items.length > 0 ? (
        <Accordion disableGutters elevation={0} sx={{ backgroundColor: 'transparent' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: 0, margin: 0, ...commonStyles }}
          >
            <Typography>
              {t(title)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Grid container spacing={1}>
              {items.map((p, i) => (
                <Grid item key={i} xs={12}>
                  <Button
                    size="large"
                    component="a"
                    href={p.href}
                    sx={{
                      ...commonStyles,
                      color: activeLink === p.href ? theme.palette.primary.main : theme.palette.text.primary,
                      backgroundColor: activeLink === p.href ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
                    }}
                  >
                    {t(p.title)}
                    {p.isNew && (
                      <Box
                        padding={0.5}
                        display="inline-flex"
                        borderRadius={1}
                        bgcolor="primary.main"
                        marginLeft={2}
                      >
                        <Typography variant="caption" sx={{ color: 'common.white', lineHeight: 1 }}>
                          new
                        </Typography>
                      </Box>
                    )}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Button
          component="a"
          href={href}
          sx={commonStyles}
        >
          {t(title)}
        </Button>
      )}
    </Box>
  );
};

NavItem.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
};

export default NavItem;

import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";
import useCertificateData from 'Hooks/useCertificateData';
import DownloadIcon from '@mui/icons-material/Download';
import Container from "components/Container";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const VerticallyAlignedBlogCardsWithShapedImage  = ({ filterCategory = "" }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const certificateData = useCertificateData('certificate');

  const [selectedGroup, setSelectedGroup] = useState(""); // Add state for selected group
  const [searchQuery, setSearchQuery] = useState(""); // State for the search input

  const sortedCertificateData = [...certificateData].sort((a, b) => {
    if (a.category === "" && b.category !== "") {
      return -1;
    }
    if (a.category !== "" && b.category === "") {
      return 1;
    }
    return a.category.localeCompare(b.category);
  });

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter data based on selected group and search query
  const filteredCertificateData = sortedCertificateData.filter((group) => {
    const matchesGroup = selectedGroup ? group.category === selectedGroup : true;
    
    // Check if the search query matches either the group category or any file name in the group
    const matchesSearch = searchQuery
      ? group.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
        group.files.some((item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : true;

    const matchesFilterCategory = filterCategory
      ? group.category.toLowerCase().includes(filterCategory.toLowerCase())
      : true;
      
    return matchesGroup && matchesSearch && matchesFilterCategory;
  });

  return (
    <Container>
      <Box
        display={"flex"}
        justifyContent={"space-between"} // Ensures the selector is aligned to the right
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        marginBottom={4}
      >
        <Box>
          <Typography fontWeight={700} variant={"h4"} gutterBottom>
            {t("certificates.title")}
          </Typography>
          <Typography color={"text.secondary"} variant={"h6"}>
            {t("certificates.subText")}
          </Typography>
        </Box>
      </Box>

      <Box>
        {/* Search bar for filtering certificates by name or group name */}
        <TextField
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder={t("certificates.filterByProductCategory")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: "8px",
            boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.1)`,
            marginBottom: 4,
          }}
        />
      </Box>

      {filteredCertificateData.map((group, groupIndex) => (
        <Box key={groupIndex} marginBottom={4}>
          <Typography variant="h5" gutterBottom>
            {group.category}
          </Typography>
          <Grid container spacing={4}>
            {group.files.map((item, i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    border: `1px solid ${theme.palette.divider}`,
                    boxShadow: 'none',
                    position: 'relative',
                  }}
                >
                  <Box
                    component={"a"}
                    href={item.link}
                    display={"flex"}
                    flexDirection={"column"}
                    width={1}
                    height={1}
                    sx={{
                      textDecoration: "none",
                      transition: "all .2s ease-in-out",
                      "&:hover": {
                        transform: `translateY(-${theme.spacing(1 / 2)})`,
                      },
                    }}
                  >
                    <CardContent>
                      <Box display="flex" justifyContent="space-between">
                        <Box>
                          <Typography
                            variant="h6"
                            color="text.primary"
                            fontWeight="bold"
                            gutterBottom
                          >
                            {item.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Additional Information
                          </Typography>
                        </Box>
                        <DownloadIcon
                          href={item.link}
                          target="_blank"
                          sx={{
                            color: theme.palette.primary.main,
                            position: 'absolute',
                            top: theme.spacing(1),
                            right: theme.spacing(1),
                          }}
                        >
                          <img
                            src="/assets/download.svg"
                            alt="Download"
                            style={{ width: 20, height: 20 }} 
                          />
                        </DownloadIcon>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        marginTop={2}
                      >
                        <Typography variant="caption" color="text.secondary">
                          PDF
                        </Typography>
                      </Box>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Container>
  );
};

export default VerticallyAlignedBlogCardsWithShapedImage;

import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Container from "components/Container";
import { useTranslation } from "react-i18next";
import useCertificateData from 'Hooks/useCertificateData';


const certificateData = [
  {
    category: "Our Certificates",
    files: [
      {
        name: "ISO 14001:2015-10 (pl)",
        thumbnail:
          "https://www.pss-offshore.com/wp-content/uploads/2018/12/ISO-14001_2015-Certificate.jpg",
        link: "https://www.elektronik.ropla.eu/pl/firma/certyfikaty/ISO_14001_2015-10_pl.pdf",
      },
      {
        name: "ISO 9001:2015-10 (en)",
        thumbnail:
          "https://www.pss-offshore.com/wp-content/uploads/2018/12/ISO-14001_2015-Certificate.jpg",
        link: "https://www.elektronik.ropla.eu/en/firma/certyfikaty/?f=ISO+9001%3A2015-10+%28en%29_0_4.pdf",
      },
    ],
  },
  {
    category: "Manufacturer's ISO Certificates ",
    files: [
      {
        name: "IATF 16949 Betterfuse",
        thumbnail:
          "https://www.pss-offshore.com/wp-content/uploads/2018/12/ISO-14001_2015-Certificate.jpg",
        link: "https://www.elektronik.ropla.eu/pl/firma/certyfikaty/?f=ISO+9001%3A2015-10+%28pl%29_0_3.pdf",
      },
      {
        name: "ISO 14001:2015 Betterfuse",
        thumbnail:
          "https://www.compel-rail.sk/wp-content/uploads/ISO-2023-EN_thumbnail.jpg",
        link: "https://www.elektronik.ropla.eu/sites/default/files/certyfikaty/ISO_9001_2015_PL.pdf",
      },
      {
        name: "IATF16949: 2016 Bothhand",
        thumbnail:
          "https://www.pss-offshore.com/wp-content/uploads/ABS-Certificate-1p.jpg",
        link: "https://www.elektronik.ropla.eu/sites/default/files/certyfikaty/ISO_9001_2015_PL.pdf",
      },
      {
        name: "ISO 45001:2018 Huawei",
        thumbnail:
          "https://asset.source.thenbs.com/api/thumbnail/98e3eb59-b49b-4b5e-9c7c-8e207d5c16e8",
        link: "https://www.elektronik.ropla.eu/sites/default/files/certyfikaty/ISO_9001_2015_PL.pdf",
      },
      {
        name: "ISO 9001:2015 Okaya",
        thumbnail:
          "https://www.pss-offshore.com/wp-content/uploads/PSS-MD-Certificate-ISO-45001-21_24-scaled.jpg",
        link: "https://www.elektronik.ropla.eu/sites/default/files/certyfikaty/ISO_9001_2015_PL.pdf",
      },
      {
        name: "ISO/TS 16949:2016 PDC",
        thumbnail:
          "https://image.made-in-china.com/202f0j00WJLaEhunCMok/Pet-Fibre-Polyester-Fiber-for-Asphalt-Concrete-with-SGS-ISO-Certification.jpg",
        link: "https://www.elektronik.ropla.eu/sites/default/files/certyfikaty/ISO_9001_2015_PL.pdf",
      }
    ],
  },
  {
    category: "Distribution Agreements",
    files: [
      {
        name: "Cooltron",
        thumbnail:
          "https://ernestlowe.co.za/wp-content/uploads/2022/05/JPG-1_eZertifikat-RZ-45001_2018-450521115_1_eng_Page_1.jpg",
        link: "https://www.elektronik.ropla.eu/sites/default/files/certyfikaty/ISO_9001_2015_PL.pdf",
      },
      
      {
        name: "Murrplastik",
        thumbnail:
          "https://asset.source.thenbs.com/api/thumbnail/98e3eb59-b49b-4b5e-9c7c-8e207d5c16e8",
        link: "https://www.elektronik.ropla.eu/sites/default/files/certyfikaty/ISO_9001_2015_PL.pdf",
      },
      {
        name: "Walsin",
        thumbnail:
          "https://www.pss-offshore.com/wp-content/uploads/PSS-MD-Certificate-ISO-45001-21_24-scaled.jpg",
        link: "https://www.elektronik.ropla.eu/sites/default/files/certyfikaty/ISO_9001_2015_PL.pdf",
      },
      {
        name: "PDC",
        thumbnail:
          "https://image.made-in-china.com/202f0j00WJLaEhunCMok/Pet-Fibre-Polyester-Fiber-for-Asphalt-Concrete-with-SGS-ISO-Certification.jpg",
        link: "https://www.elektronik.ropla.eu/sites/default/files/certyfikaty/ISO_9001_2015_PL.pdf",
      },
      
      {
        name: "SamYoung S&C",
        thumbnail:
          "https://asset.source.thenbs.com/api/thumbnail/98e3eb59-b49b-4b5e-9c7c-8e207d5c16e8",
        link: "https://www.elektronik.ropla.eu/sites/default/files/certyfikaty/ISO_9001_2015_PL.pdf",
      },
      {
        name: "Huawei Distribution",
        thumbnail:
          "https://www.pss-offshore.com/wp-content/uploads/PSS-MD-Certificate-ISO-45001-21_24-scaled.jpg",
        link: "https://www.elektronik.ropla.eu/sites/default/files/certyfikaty/ISO_9001_2015_PL.pdf",
      },
      {
        name: "Jamicon CAP",
        thumbnail:
          "https://image.made-in-china.com/202f0j00WJLaEhunCMok/Pet-Fibre-Polyester-Fiber-for-Asphalt-Concrete-with-SGS-ISO-Certification.jpg",
        link: "https://www.elektronik.ropla.eu/sites/default/files/certyfikaty/ISO_9001_2015_PL.pdf",
      }
    ],
  },
];


const VerticallyAlignedBlogCardsWithShapedImage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const certificateData = useCertificateData('certificate');

  const sortedCertificateData = [...certificateData].sort((a, b) => {
    if (a.category === "" && b.category !== "") {
      return -1; 
    }
    if (a.category !== "" && b.category === "") {
      return 1;
    }
    return a.category.localeCompare(b.category);
  });

  return (
    <Container>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        marginBottom={4}
      >
        <Box>
          <Typography fontWeight={700} variant={"h4"} gutterBottom>
            {t("certificates.title")}
          </Typography>
          <Typography color={"text.secondary"} variant={"h6"}>
            {t("certificates.subText")}
          </Typography>
        </Box>
      </Box>

      {sortedCertificateData.map((group, groupIndex) => (
        <Box key={groupIndex} marginBottom={4}>
          <Typography variant="h5" gutterBottom>
            {group.category}
          </Typography>
          <Grid container spacing={4}>
            {group.files.map((item, i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <Box
                  component={"a"}
                  href={item.link}
                  display={"block"}
                  width={1}
                  height={1}
                  sx={{
                    textDecoration: "none",
                    transition: "all .2s ease-in-out",
                    "&:hover": {
                      transform: `translateY(-${theme.spacing(1 / 2)})`,
                    },
                  }}
                >
                  <Card
                    width={1}
                    height={1}
                    boxShadow={4}
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{ backgroundImage: "none" }}
                  >
                    <CardMedia
                      image={item.thumbnail}
                      title={item.name}
                      sx={{
                        height: { xs: 300, md: 360 },
                        position: "relative",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <Box
                        component={"svg"}
                        viewBox="0 0 2880 480"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          color: theme.palette.background.paper,
                          transform: "scale(2)",
                          height: "auto",
                          width: 1,
                          transformOrigin: "top center",
                        }}
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z"
                          fill="currentColor"
                        />
                      </Box>
                    </CardMedia>
                    <Box component={CardContent} position={'relative'}>
                      <Typography
                        variant={"h6"}
                        gutterBottom
                        color="text.primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Container>
  );
};

export default VerticallyAlignedBlogCardsWithShapedImage;
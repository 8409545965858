import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CookieConsent = () => {
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'true') {
      setIsConsentGiven(true);
    }
    setIsLoading(false); 
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsConsentGiven(true);
  };

  if (isLoading) {

    return null;
  }

  if (isConsentGiven) {
    return null; 
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main, 
        color: theme.palette.common.white,
        textAlign: 'center',
        boxShadow: theme.shadows[3],
        zIndex: 9999,  
      }}
    >
      <p style={{ margin: 0 }}>
        We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
      </p>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAccept}
        sx={{
          marginTop: theme.spacing(1),
          backgroundColor: theme.palette.secondary.main, 
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
          },
        }}
      >
        Accept
      </Button>
    </Box>
  );
};

export default CookieConsent;

import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useTheme } from "@mui/material/styles";
import ThemeModeToggler from "components/ThemeModeToggler";
import AkygaLogo from "static/akyga.png";
import AkygaDarkLogo from "static/akyga.png";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import useCategoryGroups from 'Hooks/useCategoryGroups';

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const date = new Date();
  const year = date.getFullYear();
  const { t, i18n } = useTranslation();


  const categories = useCategoryGroups("Batteries & Rechargeable Cells", false);

  // console.log(categories);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Grid container spacing={2} sx={{ fontSize: "0.75rem" }}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom sx={{ color: "primary.main", fontSize: "1rem" }}>
              {t("footer.catalogue")}
            </Typography>
            {categories.map((category) => (
            <>
              <Link
                key={category.label}
                href={category.hasChildren ? `/products/${category.ids.join(',')}` : `/products/${category.label}/list`}
                color="textSecondary"
                underline="hover"
              >
                {category.label}
              </Link>
              <br />
            </>
          ))}
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom sx={{ color: "primary.main", fontSize: "1rem" }}>
              {t("footer.aboutUs")}
            </Typography>
            <Link href="/#about-us" color="textSecondary" underline="hover">
              {t("footer.company")}
            </Link>
            <br />
            <Link href="/#about-us" color="textSecondary" underline="hover">
              {t("footer.ourMission")}
            </Link>
            <br />
            <Link href="/distributors" color="textSecondary" underline="hover">
              {t("footer.aboutDistributors")}
            </Link>
            <br />
            {/* <Link
              href="https://www.ropla.eu"
              color="textSecondary"
              underline="hover"
            >
              Ropla Electronics
            </Link> */}
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom sx={{ color: "primary.main", fontSize: "1rem" }}>
              {t("footer.contactUs")}
            </Typography>
            <Link href="/contact" color="textSecondary" underline="hover">
              {t("footer.contact")}
            </Link>
            <br />
            <Link href="/basket" color="textSecondary" underline="hover">
              {t("footer.sendInquiry")}
            </Link>
            <br />
            <Link
              href="mailto:sales@akygabattery.com"
              color="textSecondary"
              underline="hover"
            >
              sales@akygabattery.com
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          width={1}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box display={"flex"} flexDirection="row" alignItems="flex-start">
            <Link
              href="/cookies-policy"
              color="textSecondary"
              underline="hover"
              marginRight={2}
            >
              {t("footer.cookiesPolicy")}
            </Link>
            |
            <Link
              href="/privacy-policy"
              color="textSecondary"
              underline="hover"
              marginLeft={2}
            >
              {t("footer.privacyPolicy")}
            </Link>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ bgcolor: theme.palette.primary.main, marginY: 2 }} />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={1}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box display={"flex"} alignItems={"left"} flexDirection="column">
            <Box display={"flex"} alignItems={"center"}>
              <Link
                href="https://www.facebook.com"
                color="primary"
                sx={{ marginRight: 2 }}
              >
                <FacebookIcon />
              </Link>
              <Link href="https://www.linkedin.com" color="primary">
                <LinkedInIcon />
              </Link>
            </Box>
            <Typography
              align={"center"}
              variant={"caption"}
              color="text.secondary"
              component={"p"}
            >
              &copy; Akyga Group {year}. {t("footer.allRightsReserved")}
            </Typography>
          </Box>
          <Box display={"flex"} component="a" href="/" title="Akyga" width={80}>
            <Box
              component={"img"}
              src={mode === "light" ? AkygaLogo : AkygaDarkLogo}
              height={1}
              width={1}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;

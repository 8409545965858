import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Main from 'layouts/Main'; // Assuming Main layout includes navbar, footer, etc.
import Container from 'components/Container'; // Assuming Container is your page wrapper

const NotFound = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        sx={{
          width: 1,
          height: 'calc(100vh - 64px)', // Adjusting height to account for navbar
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: theme.spacing(3),
        }}
      >
        <Container>
          <Typography variant="h1" sx={{ fontWeight: 700 }}>
            404
          </Typography>
          <Typography variant="h6" color="text.secondary" sx={{ marginBottom: 3 }}>
           Page not Found.
          </Typography>
          <Button
            component={Link}
            href="/"
            variant="contained"
            color="primary"
            size="large"
          >
            Back to Home
          </Button>
        </Container>
      </Box>
    </Main>
  );
};

export default NotFound;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const Pin = ({ x, y, info }) => {
  const [hover, setHover] = useState(false);
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'absolute',
        top: y,
        left: x,
        transform: 'translate(-50%, -50%)',
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        zIndex: 1,
        animation: 'pulse 1.5s infinite',
        '@keyframes pulse': {
          '0%': {
            boxShadow: `0 0 0 0 ${theme.palette.primary.main}70`,
          },
          '70%': {
            boxShadow: `0 0 0 10px ${theme.palette.primary.main}00`,
          },
          '100%': {
            boxShadow: `0 0 0 0 ${theme.palette.primary.main}00`,
          },
        },
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && (
        <Box
          sx={{
            position: 'absolute',
            top: -50,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '8px',
            boxShadow: theme.shadows[3],
            minWidth: '120px',
            textAlign: 'center',
            zIndex: 2, 
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-10px',
              left: '50%',
              transform: 'translateX(-50%)',
              borderWidth: '10px',
              borderStyle: 'solid',
              borderColor: 'white transparent transparent transparent',
            },
          }}
        >
          <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
            {info}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Pin;

import React, { useState } from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Orders, Form } from './components';
import Container from 'components/Container';
import Main from 'layouts/Main';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import useSendEmail from "Hooks/useSendEmail";

import { getBasketFromLocalStorage, saveBasketToLocalStorage } from "Utilities/localStorage";

import { useNavigate } from 'react-router-dom';

const Basket = () => {
  const [basket, setBasket] = useState(getBasketFromLocalStorage());
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    message: '',
  });
  const { t } = useTranslation();
  const { sendEmail, isLoading, isSuccess, error } = useSendEmail();
  const navigate = useNavigate();

  const handleRequestQuote = async () => {
    const formFilled = isFormFilled();
    if (formFilled) {
      try {
        await sendEmail(formData, basket);
        setBasket({});
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          company: '',
          message: '',
        });
        localStorage.removeItem('formData');
        localStorage.removeItem('basket');
        navigate('/quote-success');
      } catch (error) {
        console.error('Error sending email:', error);
      }
    } else {
      alert('Please fill out all required fields before requesting a quote.');
    }
  };

  const isFormFilled = () => {
    return (
      formData.firstName.trim() !== '' &&
      formData.lastName.trim() !== '' &&
      formData.email.trim() !== ''
    );
  };

  const isBasketEmpty = Object.keys(basket).length === 0;

  return (
    <Main>
       <Helmet>
        <title>{t("contactLanding.title")}</title>
      </Helmet>
      <Container>
        <Box sx={{ padding: 4 }}>
          <Grid container spacing={{ xs: 4, md: 8 }}>
            <Grid item xs={12} md={7}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="h6" fontWeight={700} marginBottom={4}>
                    {t("basket.contactInformation")}
                  </Typography>
                  <Form
                    formData={formData}
                    setFormData={setFormData}
                    handleSubmit={handleRequestQuote}
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography variant="h6" fontWeight={700} marginBottom={4}>
                {t("basket.orderSummary")}
              </Typography>
              <Card variant={'outlined'} sx={{ padding: { xs: 2, sm: 4 }}}>
                <Orders basket={basket} setBasket={setBasket}/> 
                <Button
                  variant={'contained'}
                  size={'large'}
                  fullWidth
                  onClick={handleRequestQuote}
                  disabled={!isFormFilled() || isBasketEmpty || isLoading} 
                  sx={{ marginTop: 2, opacity: isBasketEmpty || isLoading ? 0.5 : 1 }}
                >
                  {isLoading ? t("basket.sending") : t("basket.requestQuote")} 
                </Button>
                {isLoading && <Typography variant={'subtitle2'} align={'center'} sx={{ marginTop: 2 }}>{t("basket.sendingMessage")}</Typography>}
                <Box
                  sx={{
                    marginTop: 2,
                    marginRight: { xs: -2, sm: -4 },
                    marginLeft: { xs: -2, sm: -4 },
                    marginBottom: { xs: -2, sm: -4 },
                    padding: { xs: 2, sm: 4 },
                    bgcolor: 'alternate.main',
                  }}
                >
                  <Stack direction={'row'} spacing={2}>
                    <Button
                      sx={{
                        color: 'text.secondary',
                      }}
                      startIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                        </svg>
                      }
                    >
                      {t("basket.contactSales")}
                    </Button>
                    <Button
                      sx={{
                        color: 'text.secondary',
                      }}
                      startIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                        </svg>
                      }
                    >
                      {t("basket.emailUs")}
                    </Button>
                  </Stack>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Main>
  );
};

export default Basket;

import { useState, useEffect } from "react";
import getTableApi from '../API/tableAPI/tableAPI';

const useTableData = (categoryName) => {
    const [data, setData] = useState([]);
  
    useEffect(() => {
      async function fetchData() {
          let tableData = await getTableApi(categoryName);
          setData(tableData);
      }
  
      fetchData(); 
  }, [categoryName]); 
  
  return data;
};
  
export default useTableData;
import React from "react";
import { useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "components/Container";

const BatteryHero = () => {
  const theme = useTheme();

  return (
    <Box
      paddingTop={"16vh"}
      minHeight={300}
      height={"auto"}
      position={"relative"}
      sx={{
        backgroundColor: '#113d88',
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          background:
            "url(https://cdn.akygacomponents.com/foto/cat/akygabattery_background2.png) no-repeat center",
          backgroundSize: "contain",
          zIndex: -1,
        }}
      />
      <Container position={"relative"} zIndex={2}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          minHeight="100%"
        >
          <Box marginBottom={4}>
            {/* <Box
              component={"img"}
              src={
                "https://static.wixstatic.com/media/03b49b_26f81054242b4757aa81c456f1d7dc2e~mv2_d_2144_1206_s_2.png/v1/fill/w_402,h_190,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/logo%20akygabattery.png"
              }
              // width={1}
            /> */}

          </Box>
        </Box>
        <Typography
              variant="h4"
              gutterBottom
              paddingLeft={'40vw'}
              sx={{
                fontWeight: 700,
                color: "common.white",
              }}
            >
             {/* akygabatteries.com */}
            </Typography> 
      </Container>
    </Box>
  );
};

export default BatteryHero;

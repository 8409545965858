import React, { useState, useEffect, useRef } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {motion} from 'framer-motion';

import Container from "components/Container";
import Pin from './components/Pin';


const mock = [
  { title: 21, subtitle: "mapHero.stats.0.subtitle", suffix: "" },
  // { title: 3000, subtitle: "mapHero.stats.1.subtitle", suffix: "" },
  { title: 14, subtitle: "mapHero.stats.2.subtitle", suffix: "" },
];

const pins = [
  {
    id: 1,
    x: '575', 
    y: '345', 
    info: "Botland",
  },
  {
    id: 2,
    x: '595', 
    y: '325', 
    info: "TME Poland",
  },
  {
    id: 3,
    x: '615', 
    y: '305', 
    info: "TME Poland",
  },
  {
    id: 4,
    x: '610', 
    y: '360', 
    info: "TME Poland",
  },
  {
    id: 5,
    x: '675', 
    y: '225', 
    info: "Evita Lemona",
  },
  {
    id: 6,
    x: '530', 
    y: '120', 
    info: "Agora Sourcing",
  },
  {
    id: 7,
    x: '467', 
    y: '230', 
    info: "Agora Sourcing Sps",
  },
  {
    id: 8,
    x: '485', 
    y: '335', 
    info: "TME Germany",
  },
  {
    id: 9,
    x: '355', 
    y: '335', 
    info: "TME Germany",
  },
  {
    id: 10,
    x: '320', 
    y: '350', 
    info: "TME Germany",
  },
  {
    id: 11,
    x: '230', 
    y: '300', 
    info: "UK",
  },
  {
    id: 12,
    x: '420', 
    y: '520', 
    info: "Italy",
  },
  {
    id: 13,
    x: '145', 
    y: '645', 
    info: "TME Spain",
  },
  {
    id: 14,
    x: '575', 
    y: '380', 
    info: "Botland",
  },
  {
    id: 15,
    x: '520', 
    y: '385', 
    info: "Botland",
  },
  {
    id: 16,
    x: '600', 
    y: '410', 
    info: "Botland",
  },
  {
    id: 17,
    x: '605', 
    y: '465', 
    info: "Botland",
  },
  {
    id: 18,
    x: '665', 
    y: '485', 
    info: "Botland",
  },
  {
    id: 19,
    x: '770', 
    y: '495', 
    info: "Botland",
  },
  {
    id: 20,
    x: '715', 
    y: '625', 
    info: "Botland",
  },
  
];

const MapHero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { t } = useTranslation();

  const LeftSide = () => {
    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
      if (viewPortEntered) {
        return;
      }
      setViewPortEntered(isVisible);
    };

    return (
      <Box >
        <Typography
          variant={"h3"}
          sx={{ fontWeight: "medium" }}
          gutterBottom
        >
          {t("mapHero.globalReach")}
        </Typography>
        <Box marginBottom={2}>
          <Typography variant={"h4"} sx={{ fontWeight: 700 }} gutterBottom>
            {t("mapHero.heading")}
          </Typography>
          <Typography color="text.secondary">
            {t("mapHero.description")}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid key={i} item xs={12} md={4}>
              <Typography color={"primary"} variant="h3" gutterBottom>
                <Box fontWeight={600}>
                  <VisibilitySensor
                    onChange={(isVisible) => setViewPortVisibility(isVisible)}
                    delayedCall
                  >
                    <CountUp
                      duration={2}
                      end={viewPortEntered ? item.title : 0}
                      start={0}
                      suffix={item.suffix}
                    />
                  </VisibilitySensor>
                </Box>
              </Typography>
              <Typography color="text.secondary" component="p">
                {t(item.subtitle)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const RightSide = () => {
    const imageRef = useRef(null);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    useEffect(() => {
      const image = imageRef.current;
      const updateImageSize = () => {
        if (image) {
          const { width, height } = image.getBoundingClientRect();
          setImageSize({ width, height });
        }
        setIsImageLoaded(true); 

      };
      updateImageSize();
      window.addEventListener('resize', updateImageSize);
      return () => window.removeEventListener('resize', updateImageSize);
    }, [isImageLoaded, imageSize]);

    const variants = {
      hidden: { opacity: 0, y: 30 },
      visible: { opacity: 1, y: 0 },
    };

    const calculatePinPosition = (coordinate, imageSize) => {
      const pinPositionPercentage = (parseInt(coordinate) / imageSize) * 100;
      return `${pinPositionPercentage}%`;
    };

    return (
      isImageLoaded &&
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end', // Aligns the map to the right
        paddingRight: { xs: 0, md: '2vw' }, // Adds padding to the right side for spacing
        paddingTop: { xs: '5vh', md: 0 }, // Adds top padding on small screens
        width: '100%', // Ensures it takes the full width of the container
      }}
      >
        <Box
          ref={imageRef}
          sx={{
            position: 'relative',
            maxWidth: "100%",
            height: "100%",
          }}
        >
          <motion.img
            component="img"
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5, delay: 0.4 }}
            variants={variants}
            src={"/assets/europe_map.svg"}
            alt="..."
            onLoad={() => setIsImageLoaded(true)}
            sx={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
              zIndex: -1,
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
            }}
          >
            {pins.map(pin => (
              <Pin
                key={pin.id}
                x={calculatePinPosition(pin.x, imageSize.width)}
                y={calculatePinPosition(pin.y, imageSize.height)}
                info={pin.info}
              />
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: "hidden",
        paddingBottom: '1vh',
      }}
    >
      <Container maxWidth={{ sm: 1, md: 1236 }}>
        <LeftSide/>
      </Container>
      <Container maxWidth={{ sm: 1, md: 1236 }}>
        <RightSide/>
        </Container>
    </Box>
  );
};

export default MapHero;

import { useState, useEffect } from 'react';

import getCategoriesApi from '../API/tableAPI/groupAPI';
 
const useCategoryDetails = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const response = await getCategoriesApi();
            setData(response);
        }   
        fetchData(); 

    }, []);

    return data;
};

export default useCategoryDetails;
// useCertificateData.js

import { useState, useEffect } from "react";
import getFilesApi from '../API/filesAPI/fileAPI';

const useCertificateData = (filesCategory) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const filesData = await getFilesApi(filesCategory);
        setData(filesData);
      } catch (error) {
        console.error("Error fetching certificate data:", error);
      }
    }

    fetchData();
  }, [filesCategory]); 

  return data;
};

export default useCertificateData;
